<script setup lang="ts">
import { computed } from 'vue'
import type { ActivityItemPayee, StatementOrderRawData } from '/~/types/api'
import { formatDate, formatDollar } from '/~/utils/format'
import PayeeIcon from '/~/components/app/payee-icon.vue'
import { useLocalization } from '/~/composables/localization'
import { useProvider } from '/~/composables/provider'
import { Statement } from '/~/composables/statements'

const props = withDefaults(
  defineProps<{
    payee: ActivityItemPayee
    order?: StatementOrderRawData // TODO: add batch order type
    isStatementOrder?: boolean
    isBatchOrder?: boolean
  }>(),
  {
    order: () => ({} as StatementOrderRawData),
    isStatementOrder: false,
    isBatchOrder: false,
  }
)

const { isBillPaymentsTemplate } = useProvider()
const { translate, formatBsb } = useLocalization()

const statement = computed(() =>
  props.order ? new Statement(props.order) : null
)

const payeeName = computed(() =>
  props.isBatchOrder
    ? props.order?.fileName
    : statement.value?.payeeName || props.payee.name || props.payee.accountName
)
</script>
<template>
  <div class="flex w-full items-end justify-between overflow-hidden">
    <div class="flex items-center space-x-4 truncate">
      <payee-icon :payee="payee" />
      <div
        class="flex flex-col truncate"
        :class="[isBatchOrder && 'gap-y-[5px]']"
      >
        <div class="overflow-hidden truncate font-bold text-eonx-neutral-800">
          {{ payeeName }}
        </div>
        <div
          v-if="isBillPaymentsTemplate"
          class="mt-1 text-sm text-eonx-neutral-600"
        >
          <template v-if="isStatementOrder">
            <div>{{ statement?.statementDateMonthName }} statement</div>
            <div>Account no. {{ statement?.clientNumber }}</div>
          </template>
          <template v-else>
            <template v-if="isBatchOrder">
              Description: {{ order.reference }}
            </template>
            <div v-else-if="payee.type === 'bankAccount'">
              {{
                translate('bankAccount.details', {
                  acc: payee.accountNumber,
                  bsb: formatBsb(payee.bsb || ''),
                })
              }}
            </div>
            <div v-else-if="payee.type === 'bpay'">
              Biller: {{ payee.billerCode }} | Ref: {{ order.reference }}
            </div>
          </template>
          <div v-if="order.date">
            Payment scheduled for
            {{ formatDate('daymonthyearnumeric', order.date) }}
          </div>
        </div>
        <div v-else class="text-sm text-eonx-neutral-600">
          <template v-if="payee.type === 'bankAccount'">
            {{
              translate('bankAccount.details', {
                acc: payee.accountNumber,
                bsb: formatBsb(payee.bsb || ''),
              })
            }}
          </template>
          <template v-else-if="payee.type === 'bpay'">
            Biller: {{ payee.billerCode }} | Ref:
            {{ order.reference }}
          </template>
        </div>
      </div>
    </div>
    <div class="text-sm text-eonx-neutral-600">
      {{ formatDollar(payee.subtotal) }}
    </div>
  </div>
</template>
