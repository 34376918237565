<script setup lang="ts">
import { computed } from 'vue'
import { useRouter } from 'vue-router/composables'
import { OrderResponse } from '/~/types/api/order'
import { FlowType } from '/~/composables/checkout/checkout-types'
import { usePayId, PayIdOrder } from '/~/composables/payment-methods'
import CompletePayId from '/~/views/checkout.v2/views/checkout/components/complete-payid/complete-payid.vue'

const router = useRouter()

const props = defineProps<{
  order: OrderResponse
  loading: boolean
  flowType: FlowType
}>()

const { onCancelPaymentOrder } = usePayId()

const payIdOrder = computed(() => {
  return new PayIdOrder({
    id: props.order.paymentMethods?.[0].name,
    reference: props.order.number,
    amount: props.order?.total,
    expiresAt: props.order?.expiresAt,
  })
})

function goToActivityListPage() {
  router.back()
}
</script>
<template>
  <complete-pay-id
    :pay-id-order="payIdOrder"
    :loading="loading"
    is-continue-hidden
    @cancel="onCancelPaymentOrder(order.number, goToActivityListPage, flowType)"
    @continue="goToHomePage()"
  />
</template>
